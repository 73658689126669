import React from "react";

import Login from "./login";
import Register from "./register";
import Verify from "./verify";

import logo from "../../img/logo-new.svg";
import Current_User from "./current_user";
import Loading from "../loading/loading";
import Request_Reset from "./request_reset";
import Change_Password from "./change_password";
import history from "../../history";

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "Login",
            username: "",
            user: null,
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        history.listen(({ action }) => {
            if (action === "POP") {
                this.refreshPage();
            }
        });

        Current_User((e) => this.SSO(e));
    }

    refreshPage() {
        let path = window.location.pathname.replace(/\//g, '');
        if (path) {
            path = path[0].toUpperCase() + path.slice(1);
            console.log(path);

            this.setState({
                display: path
            });
        } else {
            this.setState({
                display: "Login"
            });
        }
    }

    SSO(user) {
        if (user?.username && user.signInUserSession?.accessToken.payload["cognito:groups"].includes("administrators")) {
            window.location.href = "/users";
        } else {
            this.setState({
                loading: false
            });
        }
    }

    updateState(page) {
        history.push("/" + page.toLowerCase());

        this.setState({
            display: page
        });
    }

    updateUsername(e) {
        this.setState({
            username: e
        });
    }

    render() {
        let display = null;
        switch (this.state.display) {
            case "Login":
                display = <Login success={() => this.updateState("Success")} register={() => this.updateState("Register")} request_reset={() => this.updateState("Forgot_password")} />;
                break;

            case "Register":
                display = <Register login={() => this.updateState("Login")} verify={() => this.updateState("Verify")} username={(e) => this.updateUsername(e)} />;
                break;

            case "Forgot_password":
                display = <Request_Reset login={() => this.updateState("Login")} change_password={() => this.updateState("Password_reset")} username={(e) => this.updateUsername(e)} />;
                break;

            case "Password_reset":
                display = <Change_Password login={() => this.updateState("Login")} username={this.state.username} />;
                break;

            case "Verify":
                display = <Verify register={() => this.updateState("Register")} success={() => this.updateState("Success")} username={this.state.username} />;
                break;

            case "Success":
                display = null;
                this.setState({
                    loading: true
                });
                window.location.href = "/users";
                break;

            default:
                display = <Login register={() => this.updateState("Register")} />;
                break;
        }

        if (display !== null) {
            return (
                <div className="auth-body">
                    <div className="auth">
                        <div className="error">{this.state.error}</div>
                        <Loading show={this.state.loading} />
                        <img className="logo" src={logo}></img>
                        <div className="heading">Admin</div>
                        {display}
                    </div>
                </div>
            );
        } else {
            return <Loading show={this.state.loading} />;
        }
    }
}

export default Auth;