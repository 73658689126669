import { Auth } from 'aws-amplify';

export default function Current_User(props) {
    Auth.currentAuthenticatedUser({
        bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => props(user))
        .catch(err => {
            if(window.location.pathname !== "/") {
                window.location.href = "/";
            }

            props(err);
        });
}