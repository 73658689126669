import config from './config';

export default function deleteUser(username) {
    let AWS = require('aws-sdk');

    var params = {
        UserPoolId: process.env.REACT_APP_USER_POOL,
        Username: username
    };

    AWS.config.update(config);

    var cog = new AWS.CognitoIdentityServiceProvider();

    cog.adminDeleteUser(params, function (err, data) {
        if (err) console.log(err); // an error occurred
        else console.log(data);           // successful response
    });
}