import { API, graphqlOperation, Storage } from "aws-amplify";
import React from "react";
import { generatePath } from "react-router-dom";
import findDateDifference from "../../components/misc/find_date_difference";
import { options } from "../../components/video/options";
import { listPosts, listReports } from "../../graphql/queries";
import Videojs from "../../components/video/video_player";
import delete_post from "../../constants/deletePost";
import deleteReports from "../../constants/deleteReports";

class Posts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: []
        };
    }

    async componentDidMount() {
        const apiData = await API.graphql(graphqlOperation(listReports));
        this.sortData(apiData.data.listReports.items);
    }

    sortData(data) {
        let dic_arr = [];
        let dic = {};
        for (let i = 0, size = data.length; i < size; i++) {
            dic[data[i].post.id] = dic[data[i].post.id] ? dic[data[i].post.id] : data[i].post;
            let reason_arr = dic[data[i].post.id].reports ? dic[data[i].post.id].reports : [];
            reason_arr.push({ id: data[i].id, user: data[i].user, reason: data[i].reason, date: data[i].updatedAt });
            dic[data[i].post.id].reports = reason_arr;
        }
        for (const [key, value] of Object.entries(dic)) {
            dic_arr.push(value);
        }
        this.generate(dic_arr);
    }

    async generate(dic) {
        let post_arr = [];
        console.log(dic);
        for (let i = 0, size = dic.length; i < size; i++) {
            let reports_arr = [];
            for (let j = 0, size = dic[i].reports.length; j < size; j++) {
                let report = <div className="report">
                    <strong>{dic[i].reports[j].user} • {findDateDifference(dic[i].reports[j].date)}</strong>
                    <br />
                    {dic[i].reports[j].reason}
                </div>

                reports_arr.push(report);
            }
            let src = await Storage.get(dic[i].src);
            // let src = dic[i].src;
            let post = <div className="post">
                <Videojs {...options} sources={[{ src: src, type: 'video/mp4' }]} />

                <div className="content">
                    <div className="user">
                        {dic[i].user} • {findDateDifference(dic[i].updatedAt)}
                    </div>
                    <div className="description">
                        <strong>English</strong> • {dic[i].english ? dic[i].english : <i>No English description</i>}
                    </div>
                    <div className="description">
                        <strong>Rohingyalish</strong> • {dic[i].rohingyalish ? dic[i].rohingyalish : <i>No Rohingyalish description</i>}
                    </div>
                    <div className="description">
                        <strong>Bahasa Malaysia</strong> • {dic[i].malay ? dic[i].malay : <i>No Bahasa Malaysia description</i>}
                    </div>

                    <div className="reports">
                        Reports:<br/>
                        {reports_arr}
                    </div>
                </div>

                <div className="post_footer">
                    <div className="action" onClick={() => this.deleteReports(dic[i].user, dic[i].id, i)}>
                        Remove reports
                    </div>
                    <div className="action" onClick={() => this.deletePost(dic[i].user, dic[i].id, i)}>
                        Delete post
                    </div>
                </div>
            </div>;

            post_arr.push(post);
        }

        this.setState({
            posts: post_arr
        }, () => {
            console.log(this.state.posts);
        });
    }

    async deleteReports(user, id, i) {
        let posts = this.state.posts;
        let arr = posts.splice(i+1, 1);
        await deleteReports(user, id);
        this.setState({
            posts: arr
        });
    }

    async deletePost(user, id, i) {
        let posts = this.state.posts;
        let arr = posts.splice(i+1, 1);
        await delete_post(user, id);
        this.setState({
            posts: arr
        });
    }

    render() {
        return (
            <div>
                {this.state.posts.length > 0 ? this.state.posts : <i className="empty">There aren't any reported posts</i>}
            </div>
        );
    }
}

export default Posts;