import { Auth } from "aws-amplify";
import React from "react";

class Change_Password extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            new_password: "",
            loading: false,
            error: null
        };
    }

    change = () => {
        if(this.state.new_password !== ""){
        Auth.forgotPasswordSubmit(this.props.username, this.state.code, this.state.new_password)
            .then(data => {
                console.log(data);
                this.props.login();
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    error: "Code did not match"
                });
            });
        } else if (this.state.new_password.length < 8 && this.state.new_password.length >= 1) {
            this.setState({
                error: "Password must be longer than 8 characters"
            });
        } else {
            this.setState({
                error: "Please enter a new password"
            });
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            error: null
        });
    }

    render() {
        return (
            <div className="login-form">
                {/* <Loading show={this.state.loading}/> */}
                <div className="error">{this.state.error}</div>
                <input type="text" value={this.state.code} placeholder="Code" onChange={(e) => this.updateState(e, "code")}></input><br />
                <input type="password" value={this.state.new_password} placeholder="New password" onChange={(e) => this.updateState(e, "new_password")}></input><br />

                <button className="primary" onClick={this.change}>submit</button>

                <hr />

                <button onClick={this.props.login} className="secondary">Back to login</button><br />
            </div>
        );
    }
}

export default Change_Password;