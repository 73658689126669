import { API, graphqlOperation } from "aws-amplify";
import { listPosts } from "../graphql/queries";

export default async function getUserPosts(username) {
    const postData = await API.graphql(graphqlOperation(listPosts, {
        filter: {
            user: {
                eq: username
            }
        }
    }));

    return postData.data.listPosts.items;
}
