import React from 'react';

class Loading extends React.Component {
    render() {
        return (
            <div style={{display: this.props.show ? "block" : "none"}} className="loading">
                <div className="loader1"></div>
                <div className="loader2"></div>
                <div className="loader3"></div>
            </div>
        );
    }
}

export default Loading;