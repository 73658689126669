import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Auth from "./components/auth/auth";
import Admin_Dashboard from './admin/dashboard';

function App() {
	return (
		<div className="App">
			<Switch>
				<Route exact path="/" component={Auth}></Route>
				<Route path={["/dashboard", "/users", "/new_positions", "/reported_posts", "/reported_comments", "/reported_endorsements"]} component={Admin_Dashboard}></Route>
			</Switch>
		</div>
	);
}

export default App;
