import React from "react";
import { Auth } from 'aws-amplify';

class Request_Reset extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            loading: false,
            error: null
        };
    }

    reset = () => {
        // Send confirmation code to user's email
        Auth.forgotPassword(this.state.username)
            .then(data => {
                console.log(data);
                this.props.change_password();
                this.props.username(this.state.username);
            })
            .catch(err => {
                console.log(err);
                let error = "User does not exist.";
                if(err.message === "Attempt limit exceeded, please try after some time."){
                    error = "Too many attempts, try again later.";
                }

                this.setState({
                    error: error
                });
            });
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            error: null
        });
    }

    render() {
        return (
            <div className="login-form">
                {/* <Loading show={this.state.loading}/> */}
                <div className="error">{this.state.error}</div>
                <input type="text" value={this.state.username} placeholder="Username" onChange={(e) => this.updateState(e, "username")}></input><br />

                <button className="primary" onClick={this.reset}>Send code</button>

                <hr />

                <button onClick={this.props.login} className="secondary">Back to login</button><br />
            </div>
        );
    }
}

export default Request_Reset;