import { ConfirmSignIn } from "aws-amplify-react";
import React from "react";
import Modal from "../../components/modal/modal";
import Modal_Button from "../../components/modal/modal_button";
import toggle_modal from "../../components/modal/toggle_modal";
import getAllUsers from "../../constants/getAllUsers";
import Delete_Modal from "./delete_modal";
import * as Icon from "react-bootstrap-icons";
import createAdmin from "../../constants/addAdmin";
import checkAdmin from "../../constants/checkAdmin";

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            filteredUsers: null,
            list: null,
            search: "",
        };
    }

    componentDidMount() {
        getAllUsers((e) => {
            this.setState({
                users: e
            }, () => {
                console.log(this.state.users);
            });
            this.handleUsers(e);
        });
    }

    accordion(id) {
        let acc = document.getElementById("extend" + id);
        if (acc) {
            if (acc.style.maxHeight === "0px") {
                acc.style.maxHeight = acc.scrollHeight + "px";
            } else {
                acc.style.maxHeight = "0px";
            }
        }
    }

    filterSearch() {
        let search = this.state.search;

        let condition = new RegExp(search.toLowerCase());

        this.handleUsers(this.state.users.filter(function (el) {
            return condition.test(el.Username.toLowerCase()) || condition.test(el.Attributes[2].Value.toLowerCase()) || condition.test(el.Attributes[3].Value);
        }));
    }

    updateFilter(e, state) {
        let value = e.currentTarget.value;
        if (/^[a-zA-Z0-9]*$/.test(value)) {
            this.setState({
                [state]: value
            }, () => {
                this.filterSearch();
            });
        }
    }

    handleUsers(users) {
        let indArr = [];
        for (let i = 0, size = users.length; i < size; i++) {
            let ind = <div className="user_wrap">
                <div onClick={async () => {
                        checkAdmin((e) => {
                            if(e.Groups.filter(e => e.GroupName === process.env.REACT_APP_GROUP_NAME).length > 0){
                                document.getElementById(i + "_admin").style.display = "none";
                                document.getElementById(i + "_admin_already").style.display = "block";
                            }
                        }, users[i].Username);
                        toggle_modal(i);
                    }} className="user" key={i}>
                    <div className="user_item">{i + 1}.</div>
                    <div className="user_item">{users[i].Attributes[2].Value}</div>
                    <div className="user_item">{users[i].Username}</div>
                    <div className="user_item">{users[i].Attributes[3].Value}</div>
                </div >
                <Modal id={i}>
                    <div className="user_info">
                        {/* <table>
                            <tbody>
                                <tr className="user_item">
                                    <td>Favoured language:</td>
                                    <td>{users[i].Attributes[1].Value}</td>
                                </tr>
                                <tr className="user_item">
                                    <td>Biography:</td>
                                    <td>{users[i].Attributes[0].Value}</td>
                                </tr>
                            </tbody>
                        </table> */}

                        <div className="heading">Username</div>
                        <div className="info">{users[i].Username}</div>
                        <br/>
                        <div className="heading">Name</div>
                        <div className="info">{users[i].Attributes[2].Value}</div>
                        <br/>
                        <div className="heading">Number</div>
                        <div className="info">{users[i].Attributes[3].Value}</div>
                        <br/>
                        <div className="heading">Favoured Language</div>
                        <div className="info">{users[i].Attributes[1].Value}</div>
                        <br/>
                        <div className="heading">Biography</div>
                        <div className="info">{users[i].Attributes[0].Value}</div>
                        <br/>

                        <div id={i + "_admin_already"} style={{display: "none"}}>This user is an admin</div>
                        <button id={i + "_admin"} className="primary" onClick={() => createAdmin(() => {}, users[i].Username)}>
                            <div className="button_icon"><Icon.ClipboardCheck /></div>
                            Make Admin
                        </button>
                        <Modal_Button id={"User_" + i}><button className="danger"><div className="button_icon"><Icon.DashCircle /></div>Delete</button></Modal_Button>
                    </div>
                </Modal>
                <Delete_Modal remove={() => this.removeUser(i)} id={"User_" + i} id2={i} username={users[i].Username} />
            </div>;
            indArr.push(ind);
        }

        this.setState({
            filteredUsers: users,
            list: indArr
        });
    }

    removeUser(id) {
        let users = this.state.users;
        users.splice(id, 1);
        this.handleUsers(users);
    }

    render() {
        return (
            <div>
                <input className="search_bar" type="text" onChange={(e) => this.updateFilter(e, "search")} value={this.state.search} placeholder="Search..."></input>
                <i className="fas fa-search search_icon"></i>
                {/* <button onClick={() => this.filterSearch()}>Filter</button> */}
                {this.state.list}
            </div>
        );
    }
}

export default Users;