import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import Position from "../../components/positions/post";
import { listPositions } from '../../graphql/queries';

class Positions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            posts: null
        };
    }

    async componentDidMount() {
        const apiData = await API.graphql(graphqlOperation(listPositions, {
            filter: {
                verified: {
                    eq: false
                }
            }
        }));
        this.setState({
            data: apiData.data.listPositions.items
        }, () => {
            this.generatePosts();
            console.log(this.state.data);
        });
    }

    generatePosts() {
        let data = this.state.data;
        let post_arr = [];

        for(let i = 0, size = data.length; i < size; i++){
            let n = data[i];
            let language = this.props.language;
            if (language === "Bahasa Malaysia") {
                language = "Malay";
            }
            language = language.toLowerCase();
            let d = "";

            if (n[language] !== null) {
                d = n[language];
            } else if(n.english !== null) {
                d = n.english;
            } else if(n.rohingyalish !== null) {
                d = n.rohingyalish;
            } else if(n.malay !== null) {
                d = n.malay;
            }

            let pos = <Position 
                id={n.id} 
                date={n.createdAt} 
                user={n.user} 
                title={n.title} 
                location={n.location}
                english={n.english}
                rohingyalish={n.rohingyalish}
                malay={n.malay}
                number={n.phone_number} />;

            post_arr.push(pos);
        }

        this.setState({
            posts: post_arr
        });
    }

    render() {
        return (
            <div>
                <div className="page_heading">
                    Unverified positions
                </div>
                {this.state.posts}
            </div>
        );
    }
}

export default Positions;