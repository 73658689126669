const dict = {
    "Audio": {
        "English": "Audio",
        "Rohingyalish": "Abas",
        "Bahasa Malaysia": "Audio"
    }, 

    "Account": {
        "English": "Account",
        "Rohingyalish": "Hísab",
        "Bahasa Malaysia": "Akaun"
    },

    "Settings": {
        "English": "Settings",
        "Rohingyalish": "Séthín",
        "Bahasa Malaysia": "Tetapan"
    },

    "Help": {
        "English": "Help",
        "Rohingyalish": "Modot",
        "Bahasa Malaysia": "Tolonglah"
    },

    "Logout": {
        "English": "Logout",
        "Rohingyalish": "Baárkule neeiló",
        "Bahasa Malaysia": "Log keluar"
    },

    "Login": {
        "English": "Login",
        "Rohingyalish": "Bútore gól",
        "Bahasa Malaysia": "Log masuk"
    },

    "About you": {
        "English": "About you",
        "Rohingyalish": "Baabote tuñí",
        "Bahasa Malaysia": "Tentang awak"
    },

    "Already have an account?": {
        "English": "Already have an account?",
        "Rohingyalish": "Foóila asé uggwá hísab?",
        "Bahasa Malaysia": "Sudah mempunyai akaun?"
    },

    "Password": {
        "English": "Password",
        "Rohingyalish": "Húfiawaádh",
        "Bahasa Malaysia": "Kata laluan"
    },

    "Verify": {
        "English": "Verify",
        "Rohingyalish": "Tosdikgor",
        "Bahasa Malaysia": "Sahkan"
    },

    "Confirm password": {
        "English": "Confirm password",
        "Rohingyalish": "Fakkagor húfiawaádh",
        "Bahasa Malaysia": "Sahkan kata laluan"
    },

    "New password": {
        "English": "New password",
        "Rohingyalish": "Noya húfiawaádh",
        "Bahasa Malaysia": "Kata laluan baharu"
    },

    "Register": {
        "English": "Register",
        "Rohingyalish": "Rejisthar gor",
        "Bahasa Malaysia": "Daftar"
    },

    "Forgotten your password?": {
        "English": "Forgotten your password?",
        "Rohingyalish": "Foórái giyégói húfiawaádh?",
        "Bahasa Malaysia": "Lupa kata laluan?"
    },

    "Reset here": {
        "English": "Reset here",
        "Rohingyalish": "Dubara séthgor eçé",
        "Bahasa Malaysia": "Tetapkan semula di sini"
    },

    "Resend": {
        "English": "Resend",
        "Rohingyalish": "Redefeçá",
        "Bahasa Malaysia": "Hantar semula"
    },

    "Haven't received a code?": {
        "English": "Haven't received a code?",
        "Rohingyalish": "Asé no gosífaiyé ekkán koóudh?",
        "Bahasa Malaysia": "Belum terima kod?"
    },

    "Send code": {
        "English": "Send code",
        "Rohingyalish": "Defeçá koóudh",
        "Bahasa Malaysia": "Hantar kod"
    },

    "Code sent to {Phone number}": {
        "English": "Code sent to",
        "Rohingyalish": "Koóudh defeçáiye olla",
        "Bahasa Malaysia": "Kod dihantar ke"
    },

    "Wrong number?": {
        "English": "Wrong number?",
        "Rohingyalish": "Golot nombór?",
        "Bahasa Malaysia": "Salah nombor?"
    },

    "Back to login": {
        "English": "Back to login",
        "Rohingyalish": "Fith olla bútore gól",
        "Bahasa Malaysia": "Kembali ke log masuk"
    },

    "Code": {
        "English": "Code",
        "Rohingyalish": "Koóudh",
        "Bahasa Malaysia": "Kod"
    },

    "News feed": {
        "English": "News feed",
        "Rohingyalish": "Hóbor hábo",
        "Bahasa Malaysia": "Suapan berita"
    },

    "Preferred language": {
        "English": "Preferred language",
        "Rohingyalish": "Fosóni báca",
        "Bahasa Malaysia": "Bahasa pilihan"
    },

    "Language settings": {
        "English": "Language settings",
        "Rohingyalish": "Zuban séthín",
        "Bahasa Malaysia": "Tetapan bahasa"
    },

    "Audio settings": {
        "English": "Audio settings",
        "Rohingyalish": "Abas séthín",
        "Bahasa Malaysia": "Tetapan audio"
    },

    "Account settings": {
        "English": "Account settings",
        "Rohingyalish": "Hísab séthín",
        "Bahasa Malaysia": "Tetapan akaun"
    },

    "Delete account": {
        "English": "Delete account",
        "Rohingyalish": "Buzaiféla hísab",
        "Bahasa Malaysia": "Padamkan akaun"
    },

    "Delete": {
        "English": "Delete",
        "Rohingyalish": "Buzaiféla",
        "Bahasa Malaysia": "Padam"
    },

    "Update account": {
        "English": "Update account",
        "Rohingyalish": "Kiórzana hísab",
        "Bahasa Malaysia": "Kemas kini akaun"
    },

    "Name": {
        "English": "Name",
        "Rohingyalish": "Nam",
        "Bahasa Malaysia": "Nama"
    },

    "Username": {
        "English": "Username",
        "Rohingyalish": "Nam estemal goróya",
        "Bahasa Malaysia": "Nama pengguna"
    },

    "Phone number": {
        "English": "Phone number",
        "Rohingyalish": "Fún nombór",
        "Bahasa Malaysia": "Nombor telefon"
    },

    "Upload post": {
        "English": "Upload post",
        "Rohingyalish": "Uorefoóñsa poigam",
        "Bahasa Malaysia": "Muat naik pos"
    },

    "Update": {
        "English": "Update",
        "Rohingyalish": "Kiórzana",
        "Bahasa Malaysia": "Kemas kini"
    },

    "Cancel": {
        "English": "Cancel",
        "Rohingyalish": "Kensel",
        "Bahasa Malaysia": "Batal"
    },

    "Please enter your new details": {
        "English": "Please enter your new details",
        "Rohingyalish": "Meérbanigorí gól tuáñr noya tofsil",
        "Bahasa Malaysia": "Sila masukkan maklumat baru anda"
    },

    "Enter": {
        "English": "Enter",
        "Rohingyalish": "Gól",
        "Bahasa Malaysia": "Masukkan"
    },

    'Please enter "Delete" to delete': {
        "English": 'Please enter "Delete" to delete',
        "Rohingyalish": 'Meérbanigorí gól "Buzaiféla" ot buzaiéla',
        "Bahasa Malaysia": 'Sila masukkan "Padam" untuk memadam'
    },

    "Warning: this cannot be undone": {
        "English": "Warning: this cannot be undone",
        "Rohingyalish": "Warnín: yián nofaré ó agoijjá",
        "Bahasa Malaysia": "Amaran: ini tidak boleh dibuat asal"
    },

    "Upload video": {
        "English": "Upload video",
        "Rohingyalish": "Uorefoóñsa vidu",
        "Bahasa Malaysia": "Muat naik video"
    },

    "Upload audio": {
        "English": "Upload audio",
        "Rohingyalish": "Uorefoóñsa abas",
        "Bahasa Malaysia": "Muat naik audio"
    },

    "Add English description": {
        "English": "Add English description",
        "Rohingyalish": "Zomade Ingilíc tofsil",
        "Bahasa Malaysia": "Tambahkan keterangan Bahasa Inggeris"
    },

    "Add Rohingyalish description": {
        "English": "Add Rohingyalish description",
        "Rohingyalish": "Zomade Rohingyalish tofsil",
        "Bahasa Malaysia": "Tambahkan keterangan Rohingyalish"
    },

    "Add Bahasa Malaysia description": {
        "English": "Add Bahasa Malaysia description",
        "Rohingyalish": "Zomade Bahasa Malésia tofsil",
        "Bahasa Malaysia": "Tambah keterangan Bahasa Malaysia"
    },

    "User": {
        "English": "User",
        "Rohingyalish": "Estemal goróya",
        "Bahasa Malaysia": "Pengguna"
    },

    "Users": {
        "English": "Users",
        "Rohingyalish": "Estemal goróya",
        "Bahasa Malaysia": "Pengguna"
    },

    "Post": {
        "English": "Post",
        "Rohingyalish": "Poigam",
        "Bahasa Malaysia": "Hantar"
    },

    "Posts": {
        "English": "Posts",
        "Rohingyalish": "Poigams",
        "Bahasa Malaysia": "Catatan"
    },

    "Saved": {
        "English": "Saved",
        "Rohingyalish": "Basaiyé",
        "Bahasa Malaysia": "Disimpan"
    },

    "Notifications": {
        "English": "Notifications",
        "Rohingyalish": "Etelá ókkol",
        "Bahasa Malaysia": "Pemberitahuan"
    },

    "Search here": {
        "English": "Search here",
        "Rohingyalish": "Tua eçé",
        "Bahasa Malaysia": "Cari di sini"
    },

    "Search": {
        "English": "Search",
        "Rohingyalish": "Tua",
        "Bahasa Malaysia": "Cari"
    },

    "Filter your search": {
        "English": "Filter your search",
        "Rohingyalish": "Salo tuáñr tua",
        "Bahasa Malaysia": "Tapis carian anda"
    },

    "See user": {
        "English": "See user",
        "Rohingyalish": "Dehé estemal goróya",
        "Bahasa Malaysia": "Lihat pengguna"
    },

    "Report": {
        "English": "Report",
        "Rohingyalish": "Repouth",
        "Bahasa Malaysia": "Lapor"
    },

    "Why would you like to report this post": {
        "English": "Why would you like to report this post",
        "Rohingyalish": "Kiyá saá tuñí fosóngoró olla repouth yián poigam",
        "Bahasa Malaysia": "Mengapa anda ingin melaporkan siaran ini"
    },

    "Sexual content": {
        "English": "Sexual content",
        "Rohingyalish": "Jinsí mila",
        "Bahasa Malaysia": "Kandungan seksual"
    },

    "Violence": {
        "English": "Violence",
        "Rohingyalish": "Dhónga fósat",
        "Bahasa Malaysia": "Keganasan"
    },

    "Hate speech": {
        "English": "Hate speech",
        "Rohingyalish": "Íncagor hotá",
        "Bahasa Malaysia": "Ucapan benci"
    },

    "Self injury": {
        "English": "Self injury",
        "Rohingyalish": "Nize zohóm",
        "Bahasa Malaysia": "Kecederaan diri"
    },

    "Other": {
        "English": "Other",
        "Rohingyalish": "Oinno",
        "Bahasa Malaysia": "Yang lain"
    },

    "Other reasons": {
        "English": "Other reasons",
        "Rohingyalish": "Oinno wojás",
        "Bahasa Malaysia": "Sebab lain"
    },

    "Leave a comment": {
        "English": "Leave a comment",
        "Rohingyalish": "Neel ekkán nozoriya",
        "Bahasa Malaysia": "Tinggalkan komen"
    },


    "Followers": {
        "English": "Followers",
        "Rohingyalish": "Fiséfiséza",
        "Bahasa Malaysia": "Pengikut"
    },

    "Following": {
        "English": "Following",
        "Rohingyalish": "Fiséfisé zar",
        "Bahasa Malaysia": "Mengikuti"
    },

    "Your posts": {
        "English": "Your posts",
        "Rohingyalish": "Tuáñr poigams",
        "Bahasa Malaysia": "Catatan anda"
    },

    "You don't currently have any saved posts": {
        "English": "You don't currently have any saved posts",
        "Rohingyalish": "Tuñí gor no ehón asé honó basaiyé poigams",
        "Bahasa Malaysia": "Anda tidak mempunyai catatan yang disimpan sekarang"
    },

    "You have": {
        "English": "You have",
        "Rohingyalish": "Tuñí asé",
        "Bahasa Malaysia": "Anda mempunyai"
    },

    "saved posts": {
        "English": "saved posts",
        "Rohingyalish": "basaiyé poigams",
        "Bahasa Malaysia": "catatan yang disimpan"
    },

    "saved post": {
        "English": "saved post",
        "Rohingyalish": "basaiyé poigam",
        "Bahasa Malaysia": "siaran yang disimpan"
    },

    "English description": {
        "English": "English description",
        "Rohingyalish": "Ingilíc tofsil",
        "Bahasa Malaysia": "Huraian Bahasa Inggeris"
    },

    "Rohingyalish description": {
        "English": "Rohingyalish description",
        "Rohingyalish": "Rohingyalish tofsil",
        "Bahasa Malaysia": "Penerangan Rohingyalish"
    },

    "Bahasa Malaysia description": {
        "English": "Bahasa Malaysia description",
        "Rohingyalish": "Bahasa Malésia tofsil",
        "Bahasa Malaysia": "Huraian Bahasa Melayu"
    },

    "Thank you": {
        "English": "Thank you",
        "Rohingyalish": "Cúkuria",
        "Bahasa Malaysia": "Terima kasih"
    },

    "You can now check out your post on your news feed or in your account page": {
        "English": "You can now check out your post on your news feed or in your account page",
        "Rohingyalish": "Tuñí foth ehón sek baáre tuáñr poigam uore tuáñr hóbor hána yáto bútore tuáñr hísab sófa",
        "Bahasa Malaysia": "Anda kini boleh melihat catatan anda di suapan berita atau di halaman akaun anda"
    },

    "Ok": {
        "English": "Ok",
        "Rohingyalish": "Thíkasé",
        "Bahasa Malaysia": "Okey"
    },

    "Publish": {
        "English": "Publish",
        "Rohingyalish": "Sába",
        "Bahasa Malaysia": "Menerbitkan"
    },

    "This user isn't following anyone yet": {
        "English": "This user isn't following anyone yet",
        "Rohingyalish": "Yián estemal goróya ói no fiséfisé zar honikka ájjo",
        "Bahasa Malaysia": "Pengguna ini belum mengikuti sesiapa"
    },

    "This user doesn't have any followers yet": {
        "English": "This user doesn't have any followers yet",
        "Rohingyalish": "Yián estemal goróya gor no asé honó fiséfiséza ájjo",
        "Bahasa Malaysia": "Pengguna ini belum mempunyai pengikut"
    },

    "Follow": {
        "English": "Follow",
        "Rohingyalish": "Fiséfiséza",
        "Bahasa Malaysia": "Ikut"
    },

    "Unfollow": {
        "English": "Unfollow",
        "Rohingyalish": "No fiséfiséza",
        "Bahasa Malaysia": "Berhenti mengikut"
    },

    "Endorse": {
        "English": "Endorse",
        "Rohingyalish": "Tosdik",
        "Bahasa Malaysia": "Sokong"
    },

    "Endorsements": {
        "English": "Endorsements",
        "Rohingyalish": "Tosdiks",
        "Bahasa Malaysia": "Pengendorsan"
    },

    "Endorsement": {
        "English": "Endorsement",
        "Rohingyalish": "Tosdik",
        "Bahasa Malaysia": "Pengesahan"
    },

    "Your endorsements": {
        "English": "Your endorsements",
        "Rohingyalish": "Tuáñr tosdiks",
        "Bahasa Malaysia": "Sokongan anda"
    },

    "One of our administrators will review your report": {
        "English": "One of our administrators will review your report",
        "Rohingyalish": "Ek or añárár entezam goróya saá nozorsáni tuáñr repouth",
        "Bahasa Malaysia": "Salah seorang pentadbir kami akan menyemak laporan anda"
    },

    "Leave a review": {
        "English": "Leave a review",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "What do you think of {user}’s talent": {
        "English": "What do you think of {user}’s talent",
        "Rohingyalish": "",
        "Bahasa Malaysia": "Apa pendapat anda mengenai bakat"
    },

    "You don't have any notifications": {
        "English": "You don't have any notifications",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Your skills": {
        "English": "Your skills",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Skills": {
        "English": "Skills",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Under construction": {
        "English": "Under construction",
        "Rohingyalish": "Nise bañdá",
        "Bahasa Malaysia": "Dalam pembinaan"
    },

    "Select an option": {
        "English": "Select an option",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Carpentry": {
        "English": "Carpentry",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Electrician": {
        "English": "Electrician",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Plumbing": {
        "English": "Plumbing",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Painting": {
        "English": "Painting",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Manual labour": {
        "English": "Manual labour",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Home skills": {
        "English": "Home skills",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Cooking": {
        "English": "Cooking",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Sewing": {
        "English": "Sewing",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Cleaning": {
        "English": "Cleaning",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Childcare": {
        "English": "Childcare",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Baking": {
        "English": "Baking",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "What skill would you like to add": {
        "English": "What skill would you like to add?",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Select one … or write your own": {
        "English": "Select one … or write your own",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Skill": {
        "English": "Skill",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Talent": {
        "English": "Talent",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "New": {
        "English": "New",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Popular": {
        "English": "Popular",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Create an account": {
        "English": "Create an account",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Forgotten your password": {
        "English": "Forgotten your password",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Reset": {
        "English": "Reset",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Send code": {
        "English": "Send code",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Back to login": {
        "English": "Back to login",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Code": {
        "English": "Code",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "New password": {
        "English": "New password",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Submit": {
        "English": "Submit",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Password": {
        "English": "Password",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Confirm password": {
        "English": "Confirm password",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Already have an account": {
        "English": "",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Code sent to {number}": {
        "English": "Code sent to {number}",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Wrong number": {
        "English": "Wrong number",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Haven’t received a code": {
        "English": "Haven’t received a code",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Resend": {
        "English": "Resend",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Verify": {
        "English": "Verify",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Please wait": {
        "English": "Please wait",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "This will take about 30 seconds": {
        "English": "This will take about 30 seconds",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Days (d)": {
        "English": "Days (d)",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Hours (h)": {
        "English": "Hours (h)",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Seconds (s)": {
        "English": "Seconds (s)",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Weeks (w)": {
        "English": "Weeks (w)",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Years (y)": {
        "English": "Years (y)",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "2 people have liked your post": {
        "English": "2 people have liked your post",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "1 person has liked your post": {
        "English": "1 person has liked your post",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "1 person has commented on your post": {
        "English": "2 person has commented on your post",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "2 people have commented on your post": {
        "English": "2 people have commented on your post",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "{user} has uploaded a new post": {
        "English": "has uploaded a new post",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "You have received a new endorsement": {
        "English": "You have received a new endorsement",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Add an emoji": {
        "English": "Add an emoji",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Emoji": {
        "English": "Emoji",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "English": {
        "English": "English",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Rohingyalish": {
        "English": "Rohingyalish",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Bahasa Malaysia": {
        "English": "Bahasa Malaysia",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Don’t show this again": {
        "English": "Don’t show this again",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Remember to stay safe": {
        "English": "Remember to stay safe",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Don’t show your face": {
        "English": "Don’t show your face",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Don’t show your location": {
        "English": "Don’t show your location",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Oh dear, you appear to be lost": {
        "English": "Oh dear, you appear to be lost",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Go back": {
        "English": "Go back",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Sorry, something has gone wrong": {
        "English": "Sorry, something has gone wrong",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Reload": {
        "English": "Reload",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Check your internet connection": {
        "English": "Check your internet connection",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "I agree to the TalentEd User Agreement, Privacy Policy and Cookie Policy": {
        "English": "I agree to the TalentEd User Agreement, Privacy Policy and Cookie Policy",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "Are you sure you want to delete your skill? Any endorsements will be lost too": {
        "English": "Are you sure you want to delete your skill? Any endorsements will be lost too",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "": {
        "English": "",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },

    "": {
        "English": "",
        "Rohingyalish": "",
        "Bahasa Malaysia": ""
    },
}

export default dict;