import { API, graphqlOperation } from "aws-amplify";
import React from "react";
import { listComments } from "../../graphql/queries";
import * as Icon from "react-bootstrap-icons";
import findDateDifference from "../../components/misc/find_date_difference";
import Modal_Button from "../../components/modal/modal_button";
import Modal from "../../components/modal/modal";
import updateComment from "../../constants/updateComment";
import deleteComment from "../../constants/deleteComment";

class Reported_Comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            comments: []
        };
    }

    async componentDidMount() {
        const apiData = await API.graphql(graphqlOperation(listComments));
        let data = apiData.data.listComments.items.filter(e => e.report);
        console.log(data);
        this.setState({data: data});
        this.sortData(data);
    }

    sortData(data) {
        let arr = [];

        for (let i = 0, size = data.length; i < size; i++) {
            console.log(data[i]);
            let comment = <div>
                <Modal_Button id={data[i].id}>
                    <div style={{ textAlign: "left", cursor: "pointer" }}>
                        <div className="comment">
                            <div className="speech"><Icon.CaretDownFill /></div>
                            <div className="user_and_date">
                                <strong>{data[i].user}</strong> • {findDateDifference(data[i].createdAt)}
                            </div>
                            <div className="comment_content">
                                {data[i].text}
                            </div>
                        </div>
                    </div>
                </Modal_Button>
                <Modal id={data[i].id}>
                    <button className="danger" onClick={() => {
                        deleteComment(data[i].id);
                        this.removeComment(data[i]);
                    }}>Delete Comment</button>
                    <br />
                    <button className="primary" onClick={() => {
                        updateComment(data[i].id);
                        this.removeComment(data[i]);
                    }}>Remove Report</button>
                </Modal>
            </div>;

            arr.push(comment);
        }

        this.setState({
            comments: arr
        });
    }

    removeComment(e) {
        let comments = [...this.state.data];
        let index = comments.map(function (e) { return e.id; }).indexOf(e?.id);
        comments.splice(index, 1);

        this.setState({
            data: comments
        }, () => {
            this.sortData(this.state.data);
        });
    }

    render() {
        return (
            <div>
                <div className="comments_wrap">
                    <div className="comments">
                        {this.state.comments.length > 0 ? this.state.comments : <i className="empty">There aren't any reported comments</i>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Reported_Comments;