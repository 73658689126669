import React from "react";
import { Auth } from "aws-amplify";
import Loading from "../loading/loading";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
            error: null
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value,
            error: null
        });
    }

    login = () => {
        this.setState({
            loading: true
        });

        Auth.signIn(this.state.username, this.state.password)
            .then(user => {
                if(!user.signInUserSession?.accessToken.payload["cognito:groups"].includes("administrators")) {
                    this.setState({
                        error: "You must be an admin to access this site"
                    });
                } else {
                    this.props.success();
                }
            })
            .then(this.props.success)
            .catch(err => {
                let error = err.message;
                if (error !== "User does not exist.") {
                    error = "Incorrect username or password."
                }

                this.setState({
                    loading: false,
                    error: error
                });
            });
    }

    render() {
        return (
            <div className="login-form">
                <Loading show={this.state.loading}/>
                <div className="error">{this.state.error}</div>
                <input type="text" value={this.state.username} placeholder="Username" onChange={(e) => this.updateState(e, "username")}></input><br />
                <input type="password" value={this.state.password} placeholder="Password" onChange={(e) => this.updateState(e, "password")}></input><br />
                <button className="primary" onClick={this.login}>Login</button>

                {/* <hr />

                <button onClick={this.props.register} className="secondary">Create an account</button><br />

                <div className="small_text">Forgotten your password? <div onClick={this.props.request_reset} className="small_text link">Reset here</div></div> */}
            </div>
        );
    }
}

// export default withAuthenticator(Login);
export default Login;