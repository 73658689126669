import React from "react";
import { Auth } from "aws-amplify";
import Loading from "../loading/loading";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            loading: false
        }
    }

    updateState(e, state) {
        this.setState({
            [state]: e.currentTarget.value
        });
    }

    verify = () => {
        Auth.confirmSignUp(this.props.username, this.state.code, {
            // Optional. Force user confirmation irrespective of existing alias. By default set to True.
            forceAliasCreation: true
        })
            .then(data => console.log(data))
            .then(this.props.success)
            .catch(err => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    async resendConfirmationCode() {
        try {
            await Auth.resendSignUp(this.props.username);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        } else {
            return (
                <div className="login-form">
                    <div className="small_text">Code sent to +447399266184<br /><div className="small_text link" onClick={this.props.register}>Wrong number?</div></div><br/>

                    <input type="text" value={this.state.code} placeholder="Code" onChange={(e) => this.updateState(e, "code")}></input><br />
                    <button onClick={this.verify} className="primary">Verify</button><br />

                    <div className="small_text">Haven't received a code?&nbsp;</div>
                    <div className="small_text link" onClick={() => this.resendConfirmationCode()}>Resend</div>
                </div>
            );
        }
    }
}

// export default withAuthenticator(Login);
export default Login;