import config from './config';

export default function createAdmin(props, username) {
    let AWS = require('aws-sdk');

    var params = {
        GroupName: process.env.REACT_APP_GROUP_NAME,
        UserPoolId: process.env.REACT_APP_USER_POOL,
        Username: username
    };

    AWS.config.update(config);

    var cog = new AWS.CognitoIdentityServiceProvider();

    cog.adminAddUserToGroup(params, function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);           // successful response
    });
}