import React from "react";
import Modal from "../../components/modal/modal";
import Modal_Button from "../../components/modal/modal_button";
import deleteUser from "../../constants/deleteUser";
import * as Icon from "react-bootstrap-icons";
import toggle_modal from "../../components/modal/toggle_modal";

class Delete_Modal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verify: "",
        };
    }

    closeModal(id) {
        toggle_modal(id);
    }

    delete() {
        if (this.state.verify === "Delete") {
            this.closeModal(this.props.id);
            this.closeModal(this.props.id2);
            this.props.remove();
            deleteUser(this.props.username);
        }
    }

    updateVerify(e) {
        this.setState({
            verify: e.currentTarget.value
        });
    }

    render() {
        return (
            <div>
                <Modal id={this.props.id}>
                    <div className="heading">Delete {this.props.username}?</div>
                    <div className="small_text">Please enter "Delete" to delete</div>
                    <div className="error">Warning: This cannot be undone</div>
                    <input onChange={(e) => this.updateVerify(e)} type="text" placeholder='Enter "Delete"...'></input>
                    <br />
                    <button disabled={this.state.verify !== "Delete" ? "disabled" : ""} className="danger" onClick={() => this.delete()}>
                        <div className="button_icon"><Icon.DashCircle /></div>
                        Delete
                    </button>
                    <hr />
                    <button className="secondary" onClick={() => this.closeModal(this.props.id)}>
                        <div className="button_icon"><Icon.XCircle /></div>
                        Cancel
                    </button>
                </Modal>
            </div>
        );
    }
}

export default Delete_Modal;