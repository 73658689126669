import React from "react";
import logo from "../img/logo-new.svg";
import Logout from "../components/auth/logout";
import getAllUsers from "../constants/getAllUsers";
import Current_User from "../components/auth/current_user";
import deleteUser from "../constants/deleteUser";
import Modal from "../components/modal/modal";
import Modal_Button from "../components/modal/modal_button";
import Delete_Modal from "./users/delete_modal";
import Users from "./users/users";
import history from "../history";
import * as Icon from 'react-bootstrap-icons';
import Positions from "./positions/positions";
import Posts from "./posts/posts";
import Reported_Comments from "./comments/comments";
import Reported_Endorsements from "./endorsements/endorsements";

class Admin_Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "users",
            verified: false,
            language: null
        };
    }

    async componentDidMount() {
        history.listen(({ action }) => {
            if (action === "POP") {
                this.refreshPage();
            }
        });

        Current_User((e) => this.SSO(e));
    }

    refreshPage() {
        let path = window.location.pathname.replace(/\//g, '');

        this.setState({
            display: path
        });
    }

    SSO(user) {
        if (!user.signInUserSession?.accessToken.payload["cognito:groups"].includes("administrators")) {
            window.location.href = "/";
        } else if (user?.username) {
            let path = window.location.pathname.replace(/\//g, '');

            console.log(path);

            this.setState({
                verified: true,
                language: user?.attributes["custom:language_preference"],
                display: path
            });
        }
    }

    changePage(page) {
        this.setState({
            display: page
        });
    }

    loadNew(page) {
        history.push("/" + page);
        this.changePage(page);
    }

    render() {
        let display = null;
        switch (this.state.display) {
            case "users":
                display = <Users />;
                break;

            case "new_positions":
                display = <Positions language={this.state.language} />;
                break;

            case "reported_posts":
                display = <Posts />;
                break;

            case "reported_positions":
                display = <Users />;
                break;

            case "reported_comments":
                display = <Reported_Comments />;
                break;

            case "reported_endorsements":
                display = <Reported_Endorsements />;
                break;
        }

        return (
            <div className="encompass" style={{ display: this.state.verified ? "inline-block" : "none" }}>
                <div className="menu">
                    <img className="logo" src={logo} />
                    <div className="heading">Admin</div>
                    <hr />
                    <div className="item" onClick={() => this.loadNew("users")}><div className="menu_icon"><Icon.People /></div>Users</div>
                    {/* <div className="item" onClick={() => this.loadNew("new_positions")}><div className="menu_icon"><Icon.Briefcase /><Icon.Plus /></div>New positions</div> */}
                    <div className="item" onClick={() => this.loadNew("reported_posts")}><div className="menu_icon"><Icon.Newspaper /><Icon.Exclamation /></div>Reported posts</div>
                    {/* <div className="item" onClick={() => this.loadNew("reported_positions")}><div className="menu_icon"><Icon.Briefcase /><Icon.Exclamation /></div>Reported positions</div> */}
                    <div className="item" onClick={() => this.loadNew("reported_comments")}><div className="menu_icon"><Icon.ChatLeftText /><Icon.Exclamation /></div>Reported comments</div>
                    <div className="item" onClick={() => this.loadNew("reported_endorsements")}><div className="menu_icon"><Icon.HandThumbsUp /><Icon.Exclamation /></div>Reported endorsements</div>
                    <hr />
                    <div className="item"><Logout /></div>
                </div>
                <div className="body">
                    <div className="home">
                        {display}
                    </div>
                </div>
            </div>
        );
    }
}

export default Admin_Dashboard;