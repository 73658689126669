import { API, graphqlOperation } from "aws-amplify";
import { listEndorsements } from "../graphql/queries";

export default async function getEndorsements() {
    const apiData = await API.graphql(graphqlOperation(listEndorsements, {
        filter: {
            report: {
                eq: true
            }
        }
    }));
    
    return apiData.data.listEndorsements.items;
}