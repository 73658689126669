import React from "react";
import Endorsements from "../../components/user/endorsements";
import getEndorsements from "../../constants/getEndorsements";

class Reported_Endorsements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            endorsements: []
        };
    }

    async componentDidMount() {
        let res = await getEndorsements();
        this.setState({
            data: res
        });
        this.createEndorsements(res);
    }

    async createEndorsements(e) {
        let e_arr = [];

        e = e.sort((a, b) => {
            return (a.updatedAt > b.updatedAt) ? -1 : ((a.updatedAt < b.updatedAt) ? 1 : 0);
        });

        for (let i = 0, size = e.length; i < size; i++) {
            let en = <Endorsements e={e[i]} refresh={(e) => this.removeEndorsement(e)} />;
            e_arr.push(en);
        }

        this.setState({
            endorsements: e_arr
        });
    }

    removeEndorsement(e) {
        let endorsements = [...this.state.data];
        let index = endorsements.map(function (e) { return e.id; }).indexOf(e?.id);
        endorsements.splice(index, 1);

        this.setState({
            data: endorsements
        }, () => {
            this.createEndorsements(this.state.data);
        });
    }

    render() {
        return (
            <div>
                {this.state.endorsements.length > 0 ? this.state.endorsements : <i className="empty">There aren't any reported endorsements</i>}
            </div>
        );
    }
}

export default Reported_Endorsements;