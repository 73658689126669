import React from "react";
import Modal from "../modal/modal";
import Modal_Button from "../modal/modal_button";
import * as Icon from "react-bootstrap-icons";
import { deletePosition, updatePosition } from "../../graphql/mutations";
import { API } from "aws-amplify";
require('datejs');

class Position extends React.Component {
    async verify() {
        let param = {
            id: this.props.id,
            verified: true
        };

        let data = await API.graphql({ query: updatePosition, variables: { input: param } });
        console.log(data);
    }

    async delete() {
        let data = await API.graphql({ query: deletePosition, variables: { input: { id: this.props.id } } });
        console.log(data);
    }

    render() {
        return (
            <div className="position">
                <div className="title">
                    {this.props.title}
                </div>
                <div className="location">
                    {this.props.user} - {this.props.number} - {Date.parse(this.props.date).toString("MMMM dS, yyyy")}
                </div>
                <div style={{ display: this.props.src ? "block" : "none" }} className="audio_wrap">
                    <audio controls>
                        <source src={this.props.src}></source>
                    </audio>
                </div>
                <div className="description">
                    <table>
                        <tbody valign="top">
                            <tr>
                                <td>Location:</td>
                                <td>{this.props.location}</td>
                            </tr>
                            <tr>
                                <td>English:</td>
                                <td>{this.props.english}</td>
                            </tr>
                            <tr>
                                <td>Rohingyalish:</td>
                                <td>{this.props.rohingyalish}</td>
                            </tr>
                            <tr>
                                <td>Bahasa Malaysia:</td>
                                <td>{this.props.malay}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="verify_btn" onClick={() => this.verify()}>
                    Verify
                </div>
                <div className="delete_btn" onClick={() => this.delete()}>
                    Delete
                </div>
            </div>
        );
    }
}

export default Position;
