import React from "react";
import findDateDifference from "../misc/find_date_difference";
import dict from "../misc/translations";
import Modal from "../modal/modal";
import Modal_Button from "../modal/modal_button";
import toggle_modal from "../modal/toggle_modal";
import * as Icon from "react-bootstrap-icons";
import deleteEndorsement from "../../constants/deleteEndorsement";
import updateEndorsement from "../../constants/removeEndorsementReport";
require("datejs");

class Endorsements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async removeEndorsement(id) {
        await deleteEndorsement(id);
        toggle_modal(id + "_menu");
        this.props.refresh(id);
    }

    async removeReports(id) {
        await updateEndorsement(id);
        toggle_modal(id + "_menu");
        this.props.refresh(id);
    }

    render() {
        let e = this.props.e;
        return (
            <span>
                <div className="endorsement" onClick={() => toggle_modal(e.id + "_menu")}>
                    <div className="user">{e.user} • {findDateDifference(e.updatedAt)}</div>
                    <div className="review">{e.review}</div>
                </div>
                <Modal id={e.id + "_menu"}>
                    <button className="danger" onClick={() => this.removeEndorsement(e.id)}><div className="button_icon"><Icon.FolderX /></div>Delete</button>
                    <br/>
                    <button className="primary" onClick={() => this.removeReports(e.id)}>Remove report</button>
                </Modal>
            </span>
        );
    }
}

export default Endorsements;