import { API, graphqlOperation } from "aws-amplify";
import { deleteEndorsement, deleteReport, deleteSkill as deleteSkillMutation } from "../graphql/mutations";
import getUserPosts from "./getUserPosts";

export default async function deleteReports(user, id) {
    let posts = await getUserPosts(user);
    posts = posts.filter(e => e.id === id);
    
    for(let i = 0, size = posts[0].reports.items.length; i < size; i++) {
        await API.graphql(graphqlOperation(deleteReport, { input: {id: posts[0].reports.items[i].id}}));
    }
}