import React from "react";
import { Auth } from "aws-amplify";
import Country_Codes from "./country_codes";
import Loading from "../loading/loading";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            username: "",
            country_code: "+44",
            number: "",
            password: "",
            confirm_password: "",
            loading: false,
            error: null
        }
    }

    updateState(e, state) {
        let value = e.currentTarget.value.replace(/^0+/, '');

        this.setState({
            [state]: value
        });
    }

    register = () => {
        let username = this.state.username;
        let password = this.state.password;
        let phone_number = this.state.country_code + this.state.number;
        let name = this.state.name;
        if(this.state.username === ""){
            this.setState({
                error: "Please enter a username"
            });
        } else if (this.state.number === "") {
            this.setState({
                error: "Please enter your phone number"
            });
        } else if (this.state.password === "") {
            this.setState({
                error: "Please enter a password"
            });
        } else if (this.state.confirm_password === "") {
            this.setState({
                error: "Please confirm your password"
            });
        } else if (this.state.password !== this.state.confirm_password) {
            this.setState({
                error: "Passwords do not match"
            });
        } else {
            Auth.signUp({
                username,
                password,
                attributes: {
                    phone_number,
                    name
                },
                validationData: [] //optional
            })
                .then(data => console.log(data))
                .then(() => {
                    this.props.verify();
                    this.props.username(username);
                }) // switches Sign Up to Verification
                .catch(err => {
                    console.log(err);
                    this.setState({
                        loading: false,
                        error: err.message
                    });
                });
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        } else {
            return (
                <div className="login-form">
                    <div className="error">{this.state.error}</div>
                    <input type="text" value={this.state.name} placeholder="Name" onChange={(e) => this.updateState(e, "name")}></input><br />
                    <input type="text" value={this.state.username} placeholder="Username" onChange={(e) => this.updateState(e, "username")}></input><br />
                    <Country_Codes value={this.state.country_code} function={(e) => this.updateState(e, "country_code")} />
                    <input className="phone_input" type="number" value={this.state.number} placeholder="Phone number" onChange={(e) => this.updateState(e, "number")}></input><br />
                    <input type="password" value={this.state.password} placeholder="Password" onChange={(e) => this.updateState(e, "password")}></input><br />
                    <input type="password" value={this.state.confirm_password} placeholder="Confirm password" onChange={(e) => this.updateState(e, "confirm_password")}></input><br />
                    <button className="primary" onClick={this.register}>Register</button><br />

                    <div className="small_text">Already have an account?&nbsp;</div>
                    <div onClick={this.props.login} className="small_text link">Login</div>
                </div>
            );
        }
    }
}

export default Register;